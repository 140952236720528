<template>
  <div class="oral-assessment-page">
    <van-nav-bar left-arrow fixed left-text="返回" @click-left="$router.go(-1)"></van-nav-bar>
    <div class="container">
      <div class="list">
        <ul>
          <li @click="into(1)">
            <div class="background" :class="['','completed','half'][info.is_rim]"></div>
            <div class="content">
              <img src="@/assets/img/icon_rim.png" />
              <p>语音构词</p>
              <div class="tag">{{ ['待评估','已评估','评估中'][info.is_rim] }}</div>
            </div>
          </li>
          <li @click="into(2)">
            <div class="background" :class="['','completed','half'][info.is_oral]"></div>
            <div class="content">
              <img src="@/assets/img/icon_oral.png" />
              <p>口腔障碍</p>
              <div class="tag">{{ ['待评估','已评估','评估中'][info.is_oral] }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="btn">
        <van-button
          :class="{disabled: info.is_rim * 1 !== 1 || info.is_oral * 1 !== 1}"
          :disabled="info.is_rim * 1 !== 1 || info.is_oral * 1 !== 1"
          @click="submit"
        >提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "oralAssessment",
  components: {},
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id
      }
      let res = await this.$http.get('pad/oral-snag/main', { params })
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.info = res.data;
    },
    into(type) {
      switch (type) {
        case 1:
          this.$router.push({
            name: "startpingu",
            query: {
              ass_num: this.$route.query.ass_num,
              eva_type: this.$route.query.eva_type,
            },
          });
          break;
        case 2:
          this.$router.push({
            name: "oralSnag",
            query: {
              uid: this.$route.query.uid,
              review_id: this.$route.query.review_id,
              ass_time: this.$route.query.ass_time,
              time: this.$route.query.time,
            },
          });
          break;
        default:
          break;
      }
    },
    async submit() {
      if (this.info.is_rim * 1 !== 1 || this.info.is_oral * 1 !== 1) {
        return
      }
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
      }
      const res = await this.$http.post('jm-reviewfee/rim-ass', params);
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.$toast.success({
        duration: 3000,
        forbidClick: true,
        message: "评估完成",
        onClose: () => {
          this.$router.replace({
            name: "AssessmentRecords",
          });
        },
      });
    }
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.oral-assessment-page {
  background: url(~@/assets/img/bj.png) top left/100% auto no-repeat #f8f8f8;
  height: 100vh;
  padding-top: 0.46rem;
  box-sizing: border-box;
  button {
    border: none;
  }
  .van-nav-bar {
    background: none;
    &::after {
      border: none;
    }
    /deep/ .van-nav-bar__content {
      height: 0.46rem;
    }
    /deep/ .van-nav-bar__left {
      font-size: 0.14rem;
      .van-nav-bar__text {
        color: #666;
      }
      .van-icon {
        font-size: 0.14rem;
        color: #666;
        margin: 0;
      }
    }
  }
  .container {
    padding: 0.8rem 0.2rem;
    height: calc(100vh - 0.46rem);
    overflow-y: scroll;
    box-sizing: border-box;
    .list {
      ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      li {
        width: 2.33rem;
        height: 2.72rem;
        border-radius: 0.18rem;
        box-sizing: border-box;
        color: #2b1a00;
        position: relative;
        overflow: hidden;
        margin: 0 0.4rem;
        .background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 0.18rem;
          background: #fff;
          border: 1px solid #ffc4c4;
          box-sizing: border-box;
          &.completed {
            background: #fff6f6;
          }
          &.half {
            background: linear-gradient(
              #fff 0%,
              #fff 50%,
              #fff6f6 50%,
              #fff6f6 100%
            );
          }
        }
        .content {
          position: absolute;
          padding: 0.5rem 0 0;
          width: 100%;
          height: 100%;
          text-align: center;
          box-sizing: border-box;
        }
        img {
          width: 1.52rem;
        }
        p {
          font-size: 0.22rem;
          margin: 0;
        }
        .tag {
          width: 1rem;
          height: 1rem;
          background: #f14947;
          font-size: 0.14rem;
          color: #fff;
          text-align: center;
          line-height: 1.7rem;
          position: absolute;
          top: -0.5rem;
          right: -0.5rem;
          transform: rotate(45deg);
        }
      }
    }
    .btn {
      text-align: center;
      margin-top: 0.6rem;
    }
    button {
      height: 0.48rem;
      border-radius: 0.24rem;
      padding: 0 0.8rem;
      font-size: 0.2rem;
      background: #f14947;
      color: #fff;
      &.disabled {
        background: #ffebeb;
        color: #000;
      }
    }
  }
}
</style>